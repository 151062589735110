* {
  margin: 0;
}
.header{
  text-align: center;
  background-color: red;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header-title{
  color: #fff;
}
main{
  display: flex;
}
.main-description{
  text-align: center;
  margin-top: 30px;
}
.left-container {
  margin-top: 50px;
  flex-grow: 1;
  text-align: center;
}
.right-container {
  margin-top: 50px;
  flex-grow: 1;
}
.form-attached-label{
  margin: 5px;
}